import apiPath from './apiPath'
import {ref} from 'vue'
import { useRouter } from 'vue-router';

const allCountries = ()=>{
    const hata = ref(null);
    const router = useRouter();
    const path = apiPath;
    let users = JSON.parse(localStorage.getItem('user'));
    let getOptin = {
            "TokenKey": localStorage.getItem('token'),
            "UserId":users.id,
            "RequestMethodName" :"/api/country/getAll",
            "RequestMethodType" : "POST"
    };
    const allCountriesGet = async()=>{
        hata.value = null;
        try {
            const res = await fetch(path + "country/getAll", {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(getOptin), 
            })
            const data = await res.json();
            
            if(data.errorMessage == "Error, token expired."){
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                router.push({name:'login'})
            }
            else{
                if(data.isSuccess){
                    return data
                }
            }
        } catch (error) {
            hata.value = error.message
            console.log(hata.value);
        }
        
    }
    return {allCountriesGet}
}

export default allCountries