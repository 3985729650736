import apiPath from './apiPath'
import {ref} from 'vue'
import { useRouter } from 'vue-router';

const userSaveOrUpdate =()=>{
    const hata = ref(null);
    const router = useRouter();
    const path = apiPath;
    let users = JSON.parse(localStorage.getItem('user'));
    const saveOrUpdate = async(update)=>{
        hata.value = null;
        try {
            const res = await fetch(path + "user/saveOrUpdate", {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    "Id": update.id,
                    "UserTypeId": update.UserTypeId,
                    "FirstName": update.FirstName,
                    "LastName": update.LastName,
                    "UserName": update.UserName,
                    "CompanyName": update.CompanyName,
                    "Phone": update.Phone,
                    "TokenKey": localStorage.getItem('token'),
                    "UserId":users.id,
                    "brandId":update.brandId,
                    "countryId":update.countryId,
                    "IsActive":update.IsActive,
                    "IsMailAuthentication":update.IsMailAuthentication,
                    "RequestMethodName" :"/api/user/saveOrUpdate",
                    "RequestMethodType" : "POST",
                }), 
                
            })
            const data = await res.json();
            if(data.errorMessage == "Error, token expired."){
                router.push({name:'login'})               
            }
            else{
                if(data.isSuccess){
                    return data
                }
                else{
                    return data
                }
            }
        } catch (error) {
            hata.value = error.message
        }
    }
    
    return {saveOrUpdate}
}

export default userSaveOrUpdate